<template>
	<div class="modal-tbf modal-clients">
		<div class="container-modal">
			<div class="header-modal">
				<div>
					<h2 class="title">Companii care folosesc sistemele TBF</h2>
					<div class="description">Companii românești din 23 de industrii cu cifră totală de afaceri de aproape două miliarde euro (€1,764,000,000) pe an folosesc sistemele TBF.</div>
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>
			<div class="clients">
				<div class="client" v-for="client in clients">
					<div class="client-data">
						<div class="name">{{ client.company }}</div>
						<div class="founder" v-if="client.name">{{ client.name }}</div>
					</div>
					<div class="client-turnover">
						<div class="turnover" v-if="client.turnover">{{ client.turnover }}</div>
						<a class="website" :href="client.website" target="_blank" v-if="client.website">{{ client.website }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconClose from "../Icons/Close";

export default {
	data() {
		return {
			clients: [
				{ company: "Lensa", name: "Fondator Daniel Crăciun", turnover: "€26,000,000/an", website: "https://lensa.ro/" },
				{ company: "Vindem-Ieftin", name: "Fondator Cosmin Răileanu", turnover: "€15,000,000/an", website: "https://vindem-ieftin.ro" },
				{ company: "Euro Team GB", name: "Fondator Octavian Blăjan", turnover: "€17,000,000/an", website: "https://euroteamgb.com" },
				{ company: "Termene.ro", name: "Fondator Adrian Dagomir", turnover: "€2,000,000/an", website: "https://termene.ro" },
				{ company: "Deep Serv", name: "Fondator Marian Spînu", turnover: "€9,000,000/an", website: "https://www.deep-serv.ro" },
				{ company: "DWF", name: "Fondator Mihai Vînătoru", turnover: "€4,500,000/an", website: "https://dwf.ro" },
				{ company: "F64", name: "Fondator Marian Alecsiu", turnover: "€28,000,000/an", website: "https://www.f64.ro" },
				{ company: "Eucom", name: "Fondator Delia Dragomir", turnover: "€2,500,000/an", website: "https://www.eucom.ro" },
				{ company: "Fomco", name: "Fondator Bernat Nyulas", turnover: "€27,000,000/an", website: "https://www.fomcogroup.ro" },
				{ company: "Dacris", name: "Fondator Lidia Fați", turnover: "€28,000,000/an", website: "https://www.dacris.net" },
				{ company: "Izotec", name: "Fondator Ciprian Chelariu", turnover: "€8,000,000/an", website: "https://izotec.ro/en/home" },
				{ company: "Fryday", name: "Fondator Lucian Florea", turnover: "€4,100,000/an", website: "https://fryday.ro" },
				{ company: "Brutăria Frontera", name: "Fondator Alexandru Stancu", turnover: "€9,500,000/an", website: "https://brutariafrontera.ro" },
				{ company: "Eden Boutique", name: "Fondator Bogdan Ghinea", turnover: "€3,600,000/an", website: "https://www.edenboutique.ro" },
				{ company: "Blitz Imobiliare", name: "Fondator Cătălin Priscorniță", turnover: "€5,200,000/an", website: "https://www.blitz.ro" },
				{ company: "Alpha Clinic", name: "Fondator Dan Jalbă", turnover: "€2,000,000/an", website: "https://alphaclinic.ro" },
				{ company: "Rinapack", name: "Fondator Adrian Bianu", turnover: "€5,000,000/an", website: "https://www.rinapack.ro" },
				{ company: "All About Parenting", name: "Fondator Ștefan Irimia", turnover: "€2,500,000/an", website: "https://allaboutparenting.ro" },
				{ company: "Club Aventura", name: "Fondator Marian Petrescu", turnover: "€1,000,000/an", website: "https://club-aventura.ro" },
				{ company: "Domeniile Săftica", name: "Fondator Florin Marin", turnover: "€2,400,000/an", website: "https://saftica.ro" },
				{ company: "Portal Village", name: "Fondator Anca Munteanu", turnover: "€1,000,000/an", website: "https://portalvillage.ro/en" },
				{ company: "Bio-Circle Surface Technology", name: "Fondator Daniel Mereuță", turnover: "€2,400,000/an", website: "https://www.bio-circle.ro" },
				{ company: "H2O si ASANTe", name: "Fondator Iulian Tache", turnover: "€1,900,000/an", website: "https://shoph2o.ro" },
				{ company: "Terasa Florilor", name: "Fondator Dragoș Dumitrescu", turnover: "€2,000,000/an", website: "https://www.terasaflorilor.ro" },
				{ company: "Brain Remodeling Expert", name: "Fondator Monica Ion", turnover: "€700,000/an", website: "https://monicaion.ro" },
				{ company: "Minunea Naturii", name: "Fondator Anca Ienin", turnover: "€1,600,000/an", website: "https://www.minuneanaturii.ro" },
				{ company: "Compania de Acte", name: "Fondator Claudiu Zlate", turnover: "€60,000/an", website: "https://www.zlate.ro" },
				{ company: "Izolatii Conducte", name: "Fondator Dan Țigău", turnover: "€890,000/an", website: "https://www.izolatii-conducte.ro" },
				{ company: "BDM Systems", name: "Fondator Mircea Barticel", turnover: "€2,000,000/an", website: "https://acoperisuri.info" },
				{ company: "ZYK Concept", name: "Fondator Simena Zetu", turnover: "€700,000/an", website: null },
				{ company: "Airport Transfer Cars", name: "Fondator Ionel Tănasă", turnover: "€2,000,000/an", website: "https://247airporttransfer.co.uk" },
				{ company: "MW Gaze", name: "Fondator Marius Ionuț", turnover: "€1,700,000/an", website: "http://mwgaze.ro" },
				{ company: "Best Auto Vest si Rapid Auto", name: "Fondator Silvian Nanea", turnover: "€3,400,000/an", website: "https://www.rapidauto.ro" },
				{ company: "Contrail Machinery", name: "Fondator Andrei Țipău", turnover: "€6,100,000/an", website: "https://contrailmachinery.com" },
				{ company: "Brand Distribution Group", name: "Fondator Alexandru Cojocaru", turnover: "€1,700,000/an", website: "https://www.brandoffice.ro" },
				{ company: "Eucom", name: "Fondator Silviu Dragomir", turnover: "€2,500,000/an", website: "https://www.eucom.ro" },
				{ company: "Verla", name: "Fondator Viorel Verzea", turnover: "€13,500,000/an", website: "https://www.verla.ro" },
				{ company: "GB Instaplan", name: "Fondator George Brănișteanu", turnover: "€1,300,000/an", website: "https://instaplan.ro/en" },
				{ company: "Smart Wrap", name: "Fondator Melinda Mureșan", turnover: "€7,300,000/an", website: "https://www.smartwrap.ro" },
				{ company: "Miras", name: "Fondator Mircea Mihai", turnover: "€121,300,000/an", website: "https://miras.ro" },
				{ company: "Multibond Dural", name: "Fondator Dorel Deatcu", turnover: "€9,200,000/an", website: "https://www.multibonddural.ro" },
				{ company: "Rodata", turnover: "€7,900,000/an", website: "https://www.rodata.ro", name: null },
				{ company: "BBQ Ribs Grill", turnover: "€1,000,000/an", website: "http://ribsgrill.ro", name: null },
				{ company: "AQUA Publis", turnover: "€780,000/an", website: "https://www.aquapublis.com", name: null },
				{ company: "Play Solutions", name: "Fondator Nicu Plai", turnover: "€1,200,000/an", website: "https://play-solutions.ro/en_en" },
				{ company: "ATV Rom", turnover: "€24,000,000/an", website: "https://www.atvrom.ro", name: null },
				{ company: "We Sound", turnover: "€1,050,000/an", website: "https://wesound.ro", name: null },
				{ company: "All 4 Floor Distribution", turnover: "€600,000/an", website: null, name: null },
				{ company: "Pro Confort Windows", turnover: "€2,000,000/an", website: "https://www.proconfort.ro", name: null },
				{ company: "Comma-Tech", turnover: "€820,000/an", website: "https://www.commatech.ro/ro", name: null },
				{ company: "Oltenia Garden", turnover: "€4,000,000/an", website: "https://olteniagarden.ro", name: null },
				{ company: "Fortify Solutions", turnover: "€215,000/an", website: null, name: null },
				{ company: "Everest International Holding", name: "Fondator Horia Stanescu", turnover: "€5,400,000/an", website: "https://www.everest-int.ro" },
				{ company: "S E Innovative Costruction", turnover: "€1,400,000/an", website: "https://se-innovativeconstruction.com", name: null },
				{ company: "Coliseum", turnover: "€23,000,000/an", website: "LinkedIn", name: null },
				{ company: "Flego", name: "Fondator Mirel Fleancu", turnover: "€6,300,000/an", website: "https://www.espressocafe.ro/despre-noi" },
				{ company: "Elecon Plus", turnover: "€2,500,000/an", website: "https://elecon.ro", name: null },
				{ company: "ArenaCAD", name: "Fondator Andrei Oros", turnover: "€820,000/an", website: "https://arenacad.com" },
				{ company: "Cupio.ro", turnover: "€12,200,000/an", website: "https://cupio.ro", name: null },
				{ company: "East Electric", turnover: "€3,400,000/an", website: "https://www.eastelectric.ro", name: null },
				{ company: "P D Polidom", turnover: "€1,700,000/an", website: "https://polidomservice.ro", name: null },
				{ company: "Volter", turnover: "€2,800,000/an", website: "https://volter.ro", name: null },
				{ company: "Saris fruit", turnover: "€1,500,000/an", website: "Website", name: null },
				{ company: "Diacostampet", name: "Fondator Diana Vochițoiu", turnover: "€2,300,000/an", website: "https://arhivadia.ro" },
				{ company: "Inox Metal SSR", name: "Fondator Daniel Ion Bogdan", turnover: "€4,800,000/an", website: "https://www.inoxmetalssr.ro" },
				{ company: "Cuiul", name: "Fondator Paul Cojocariu", turnover: "€18,000,000/an", website: "https://cuiul.ro" },
				{ company: "Servelect", name: "Fondator Claudiu Boca", turnover: "€8,500,000/an", website: "https://servelect.ro" },
				{ company: "Autorun", name: "Fondator George Aldea", turnover: "€1,400,000/an", website: "https://auto-run.ro" },
				{ company: "Panamarom", name: "Fondator Petru Amariei", turnover: "€4,400,000/an", website: "https://www.facebook.com/PanamaromSRL/" },
				{ company: "Ziroma", name: "Fondator Robert Grinberg", turnover: "€1,300,000/an", website: "https://www.facebook.com/profile.php?id=100065019012192&locale=ro_RO" },
				{ company: "Terraverde", name: "Fondator Dragos Magirescu", turnover: "€1,800,000/an", website: "https://www.terraverde.com.ro" },
				{ company: "Nexxon", turnover: "€44,000,000/an", website: "https://www.nexxon.ro", name: null },
				{ company: "Tuvkarpat", turnover: "€800,000/an", website: "https://tuvkarpat.ro", name: null },
				{ company: "Intercom Energy", turnover: "€2,700,000/an", website: null, name: null },
				{ company: "Novus Medical Clinica", turnover: "€1,600,000/an", website: "https://novusmedical.ro", name: null },
				{ company: "Corident Pro", turnover: "€1,400,000/an", website: "corident.ro", name: null },
				{ company: "DavTim-Bau", turnover: "€1,430,000/an", website: "http://davtim-bau.ro", name: null },
				{ company: "MTM Izolatii", name: "Fondator Marius Trif", turnover: "€8,400,000/an", website: "http://www.e-izolatii.ro" },
				{ company: "Olecom Prod", turnover: "€5,500,000/an", website: "https://olecom.ro", name: null },
				{ company: "Odor Control", turnover: "€900,000/an", website: "http://www.odorcontrol.ro", name: null },
				{ company: "Next Alternative Advertising", turnover: "€390,000/an", website: "https://alternative-advertising.ro", name: null },
				{ company: "Trend New Communication", turnover: "€300,000/an", website: "https://tncgroup.ro", name: null },
				{ company: "Revismed Medical", turnover: "€1,850,000/an", website: "http://revismed.ro", name: null },
				{ company: "Dentocalm", turnover: "€900,000/an", website: "https://dentocalm.ro/en", name: null },
				{ company: "Instgat", turnover: "€1,200,000/an", website: "https://www.instgat.ro", name: null },
				{ company: "Vizual Med", name: "Fondator Dacia Hîncu", turnover: "€630,000/an", website: "https://vizualmed.ro" },
				{ company: "SVT Electronics", turnover: "€1,550,000/an", website: "https://svt.ro", name: null },
				{ company: "Artur Smart", turnover: "€450,000/an", website: "https://hotelsmart.ro", name: null },
				{ company: "Orzelowska Beauty", turnover: "€540,000/an", website: "https://www.orzelowska.ro", name: null },
				{ company: "Corporation Construct", turnover: "€4,900,000/an", website: "https://corporationconstruct.ro", name: null },
				{ company: "Web Ventures", turnover: "€500,000/an", website: "https://webventures.ro", name: null },
				{ company: "Seatbelt Consulting", turnover: "€4,400,000/an", website: "https://www.seatbelt.ro", name: null },
				{ company: "Paltinul Imobiliare", name: "Fondator Carmen Olareanu", turnover: "€150,000/an", website: "https://www.paltinul.ro" },
				{ company: "Mercator Analist", turnover: "€250,000/an", website: "https://m-a.ro", name: null },
				{ company: "Glob Star Trade", turnover: "€10,000,000/an", website: "https://www.tedelectric.eu", name: null },
				{ company: "Narcis Cernea Fitness Nutrition", turnover: "€400,000/an", website: "https://narciscernea.com", name: null },
				{ company: "Franchwise", name: "Fondator Raluca Stanciu", turnover: "€500,000/an", website: "https://www.francize.ro" },
				{ company: "Eatble Grup", turnover: "€1,090,000/an", website: "https://eatble.com", name: null },
				{ company: "Zara Design", turnover: "€1,300,000/an", website: "https://www.foggi.ro", name: null },
				{ company: "Kibo Delice", turnover: "€750,000/an", website: "https://www.facebook.com/SophiePatisserieConstanta/", name: null },
				{ company: "Lindab", turnover: "€17,000,000/an", website: "https://www.lindab.ro", name: null },
				{ company: "Agile Craftsmanship", turnover: "€200,000/an", website: "https://www.axpacademy.com", name: null },
				{ company: "Gabrielle Design", turnover: "€1,200,000/an", website: "https://uniforme-hill.ro", name: null },
				{ company: "Stera Chemicals", turnover: "€41,000,000/an", website: "https://sterachemicals.ro", name: null },
				{ company: "Management Certification", turnover: "€500,000/an", website: "https://www.mcert.ro", name: null },
				{ company: "Medist", turnover: "€50,000,000/an", website: "https://www.medist.ro", name: null },
				{ company: "Docprocess", turnover: "€2,600,000/an", website: "https://doc-process.com", name: null },
				{ company: "Ideal Feroluc", turnover: "€5,200,000/an", website: "https://www.idealferoluc.ro", name: null },
				{ company: "Arca Performance", turnover: "€2,000,000/an", website: "https://www.arcasolutions.ro", name: null },
				{ company: "Gsd Software Technology", turnover: "€800,000/an", website: "https://gsdgroup.net", name: null },
				{ company: "Next Level Accounting", turnover: "€330,000/an", website: "https://profit2cash.ro", name: null },
				{ company: "Dem Media", turnover: "€465,000/an", website: "https://demmedia.ro", name: null },
				{ company: "Maurer", turnover: "€20,000,000/an", website: "https://www.maurerimobiliare.ro", name: null },
				{ company: "Conart", turnover: "€3,000,000/an", website: "https://www.tamos.ro", name: null },
				{ company: "Termoplast", name: "Fondator Daniela Neculai", turnover: "€15,000,000/an", website: "https://termoplast.ro/ro/home" },
				{ company: "Bibi Touring", name: "Fondator Ana Voican", turnover: "€9,000,000/an", website: "https://www.bibi.ro" },
				{ company: "Alm Power Group", turnover: "€4,300,000/an", website: "https://www.almpower.ro", name: null },
				{ company: "Fastbit", turnover: "€830,000/an", website: "http://www.fastbit.ro", name: null },
				{ company: "Accent Protrade", turnover: "€4,200,000/an", website: "http://www.accentgroup.ro", name: null },
				{ company: "Stere și Asociații", turnover: "€460,000/an", website: "https://www.stere.ro", name: null },
				{ company: "Envirotronic", turnover: "€4,800,000/an", website: "https://envirotronic.ro", name: null },
				{ company: "Forest Design", name: "Fondator Bogdan Candrea", turnover: "€460,000/an", website: "https://www.forestdesign.ro/" },
				{ company: "Domina Imobiliare", turnover: "€250,000/an", website: "https://www.domina.ro", name: null },
				{ company: "Crystal Dental Clinic", turnover: "€2,300,000/an", website: "https://crystaldentalclinic.ro", name: null },
				{ company: "Bytex", turnover: "€1,900,000/an", website: "https://bytex.net", name: null },
				{ company: "Automatic Invest", turnover: "€975,000/an", website: "https://www.automatic.ro", name: null },
				{ company: "Memory", turnover: "€1,550,000/an", website: "www.memory.ro", name: null },
				{ company: "Arco Expert", turnover: "€3,400,000/an", website: "https://arcoexpert.ro", name: null },
				{ company: "Biacon Activ", turnover: "€680,000/an", website: "https://www.biacon.ro", name: null },
				{ company: "Personally me Bijuterii", turnover: "€160,000/an", website: "https://www.personallyme.ro", name: null },
				{ company: "Qzine Catering", turnover: "€985,000/an", website: "https://qzine.ro", name: null },
				{ company: "Soft Tehnica", turnover: "€3,500,000/an", website: "https://soft-tehnica.com/ro", name: null },
				{ company: "Optimal Auto Expert", turnover: "€750,000/an", website: "https://serviceoptimal.ro", name: null },
				{ company: "Grup General ID", turnover: "€2,400,000/an", website: "https://www.facebook.com/GrupGeneralID/", name: null },
				{ company: "Mac GSM", turnover: "€220,000/an", website: "https://www.macgsm.ro", name: null },
				{ company: "D C Optik", turnover: "€1,300,000/an", website: "https://www.interoptik.ro", name: null },
				{ company: "Decadance Art Bar", turnover: "€475,000/an", website: "https://www.decadance.ro", name: null },
				{ company: "Nova Grup Vices", turnover: "€1,020,000/an", website: "https://novagrupvices.ro", name: null },
				{ company: "Black Light", turnover: "€1,600,000/an", website: "https://www.blacklight.ro", name: null },
				{ company: "Auto Ovarom", turnover: "€1,800,000/an", website: "https://www.autoovarom.ro", name: null },
				{ company: "Centrul de Calculatoare", turnover: "€4,300,000/an", website: "https://www.centrul.com", name: null },
				{ company: "ACDA", turnover: "€200,000/an", website: "https://www.acda.ro/index", name: null },
				{ company: "Creative Code", turnover: "€900,000/an", website: "https://www.creative-tim.com", name: null },
				{ company: "Magazinul Oana", turnover: "€470,000/an", website: "https://magazinuloana.ro", name: null },
				{ company: "Azaria", turnover: "€5,700,000/an", website: "https://www.azariafood.ro", name: null },
				{ company: "Welthaus", turnover: "€8,400,000/an", website: "https://www.welthaus.ro", name: null },
				{ company: "Aviatia", turnover: "€220,000/an", website: "https://scoaladesoferigalati.ro", name: null },
				{ company: "Auto Soft", turnover: "€20,000,000/an", website: "https://www.auto-soft.ro", name: null },
				{ company: "Electro Del", turnover: "€1,600,000/an", website: "https://www.electrodel.ro", name: null },
				{ company: "GBT", turnover: "€980,000/an", website: "https://www.cantareindustriale.com", name: null },
				{ company: "Marketing Deck", turnover: "€250,000/an", website: "https://marketingdeck.com", name: null },
				{ company: "Straton Digital", turnover: "€750,000/an", website: "https://www.straton.digital", name: null },
				{ company: "Don't Pay Full", turnover: "€3,800,000/an", website: "https://www.dontpayfull.com", name: null },
				{ company: "Web Future Studio", turnover: "€800,000/an", website: "https://www.webfuturestudio.com", name: null },
				{ company: "Caretta", turnover: "€12,500,000/an", website: "https://caretta.ro", name: null },
				{ company: "Organizația de caritate Ecce Homo", website: "https://ecce-homo.ro/en", name: null, turnover: null },
				{ company: "Mura CSI", turnover: "€500,000/an", website: "https://www.facebook.com/mura.csi", name: null },
				{ company: "Danila Parteners", turnover: "€200,000/an", website: "https://danilapartners.ro", name: null },
				{ company: "Topo Live Engineering", turnover: "€180,000/an", website: "https://topolive.ro/ro", name: null },
				{ company: "Demco Lighting", turnover: "€700,000/an", website: "http://demco.ro", name: null },
				{ company: "Amva", turnover: "€210,000/an", website: "https://amva.ro", name: null },
				{ company: "Olahus Trading", turnover: "€4,700,000/an", website: null, name: null },
				{ company: "Art Match", turnover: "€17,000,000/an", website: "https://artmatch.net", name: null },
				{ company: "Bravo", turnover: "€22,500,000/an", website: "https://www.bravo.ro", name: null },
				{ company: "Pro Invest", turnover: "€6,000,000/an", website: "https://web.proinvest.com.ro/", name: null },
				{ company: "PTC Auto", turnover: "€2,500,000/an", website: "https://ptc-auto.ro", name: null },
				{ company: "Select Soft", turnover: "€500,000/an", website: "https://selectsoft.ro", name: null },
				{ company: "RAI 88 Beton Rom", turnover: "€7,000,000/an", website: "https://statiebeton.ro", name: null },
				{ company: "Piata 9", turnover: "€3,600,000/an", website: "https://piata9.ro", name: null },
				{ company: "Bijubox", turnover: "€260,000/an", website: "https://bijubox.ro", name: null },
				{ company: "Coopervision", turnover: "€300,000/an", website: "https://coopervision.com.ro", name: null },
				{ company: "Hattrick Sport", turnover: "€1,800,000/an", website: "https://gazonartificial.com.ro", name: null },
				{ company: "Hidrogeofor", turnover: "€800,000/an", website: "https://hidrogeofor.ro", name: null },
				{ company: "Diana", turnover: "€78,000,000/an", website: "https://www.diana.com.ro", name: null },
				{ company: "Sabetti Med", turnover: "€1,400,000/an", website: "https://sabettimed.com", name: null },
				{ company: "Modulator Architecture Office", turnover: "€230,000/an", website: "https://modulor.ro", name: null },
				{ company: "Selmark Advertising", turnover: "€100,000/an", website: "https://agentie.marketing", name: null },
				{ company: "Green Point", turnover: "€7,300,000/an", website: "https://greenpointromania.ro", name: null },
				{ company: "Raribel", turnover: "€210,000/an", website: null, name: null },
				{ company: "Sfinx Camper Converstion", turnover: "€990,000/an", website: "https://sfinx3c.ro", name: null },
				{ company: "Submit", turnover: "€320,000/an", website: null, name: null },
				{ company: "Vallum", turnover: "€280,000/an", website: "https://www.vallum.ro", name: null },
				{ company: "Simtel", turnover: "€27,500,000/an", website: "https://www.simtel.ro", name: null },
				{ company: "Set Prod Com", turnover: "€10,000,000/an", website: "https://www.setprodcom.ro", name: null },
				{ company: "Leads Heroes", turnover: "€215,000/an", website: null, name: null },
				{ company: "ABC Support Vision", turnover: "€230,000/an", website: null, name: null },
				{ company: "Fiscal Online", turnover: "€480,000/an", website: "https://www.fiscalonline.ro", name: null },
				{ company: "Soocces Online", turnover: "€1,000,000/an", website: null, name: null },
				{ company: "Palibo Cream", turnover: "€1,000,000/an", website: "https://palibo.ro", name: null },
				{ company: "Zexe Braserie", turnover: "€1,100,000/an", website: "https://zexebraserie.ro", name: null },
				{ company: "Rohaus", turnover: "€1,000,000/an", website: null, name: null },
				{ company: "Restaurant Shanghai", turnover: "€2,100,000/an", website: "https://www.restaurantshanghai.ro", name: null },
				{ company: "Tandia Sistem", turnover: "€295,000/an", website: null, name: null },
				{ company: "Proamb", turnover: "€870,000/an", website: "https://proamb.ro", name: null },
				{ company: "Smarsoft Electronic", turnover: "€430,000/an", website: "https://www.smarsoft.ro", name: null },
				{ company: "Costa Utilaje", turnover: "€5,100,000/an", website: "https://costautilaje.ro", name: null },
				{ company: "Ethics Beauty", turnover: "€1,400,000/an", website: "https://ethicsbeauty.ro", name: null },
				{ company: "ASK DG", turnover: "€480,000/an", website: "https://wellnessist.com", name: null },
				{ company: "Tecnoservice Equipment", turnover: "€5,600,000/an", website: "https://www.tecnos.ro", name: null },
				{ company: "Logarithmic Services", turnover: "€1,500,000/an", website: "https://logarithmicsolutions.com", name: null },
				{ company: "Invent Media", turnover: "€560,000/an", website: "https://inventmedia.ro", name: null },
				{ company: "Auto Class", turnover: "€2,800,000/an", website: "https://www.autoclass.ro", name: null },
				{ company: "Alcom", turnover: "€550,000/an", website: null, name: null },
				{ company: "MBR Soft", turnover: "€550,000/an", website: "https://www.gremini.com", name: null },
				{ company: "Globe Trade Ventures", turnover: "€1,100,000/an", website: null, name: null },
				{ company: "Alchimeia", turnover: "€800,000/an", website: "https://alchimeia.ro", name: null },
				{ company: "Carpintek", turnover: "€5,000,000/an", website: "https://www.carpintek.es", name: null },
				{ company: "IBC Focus", turnover: "€800,000/an", website: "https://www.ibcfocus.ro", name: null },
				{ company: "Braldico", turnover: "€3,400,000/an", website: "https://www.facebook.com/Braldico1997", name: null },
				{ company: "Sengher Sisteme", turnover: "€1,200,000/an", website: null, name: null },
				{ company: "Medident", turnover: "€1,900,000/an", website: "https://medidentexim.ro", name: null },
				{ company: "Ebin", turnover: "€670,000/an", website: "https://ebin.ro", name: null },
				{ company: "Secoplan", turnover: "€600,000/an", website: "https://www.secoplan.ro", name: null },
				{ company: "Santa Vida Med", turnover: "€400,000/an", website: "https://www.policlinicavivamed.ro", name: null },
				{ company: "Luxorient", turnover: "€670,000/an", website: null, name: null },
				{ company: "Dorna Medical", turnover: "€11,600,000/an", website: "https://www.dornamedical.ro/", name: null },
				{ company: "Passionate Clinic", turnover: "€290,000/an", website: "https://passionateclinic.ro", name: null },
				{ company: "Golden Imobiliare", turnover: "€300,000/an", website: "https://www.goldenimobiliare.ro", name: null },
				{ company: "Rock Trans", turnover: "€2,300,000/an", website: "https://rocktrans.ro", name: null },
				{ company: "Zahar la Plic", turnover: "€520,000/an", website: "https://zaharlaplic.ro", name: null },
				{ company: "Cofetaria Narcisa", turnover: "€1,900,000/an", website: "https://cofetaria-narcisa.ro", name: null },
				{ company: "Car Oanceakim", turnover: "€960,000/an", website: null, name: null },
				{ company: "Mebelissimo", turnover: "€985,000/an", website: "https://www.mobman.ro", name: null },
				{ company: "Opsimus", turnover: "€3,100,000/an", website: "https://opsimus.ro", name: null },
				{ company: "Finomatex", turnover: "€360,000/an", website: "https://finomatex.com", name: null },
				{ company: "Triton", turnover: "€48,000,000/an", website: "http://www.triton.ro/", name: null },
				{ company: "Hennlich", turnover: "€2,900,000/an", website: "https://www.hennlich.ro", name: null },
				{ company: "Recover", turnover: "€500,000/an", website: null, name: null },
				{ company: "Rsbauro", turnover: "€290,000/an", website: null, name: null },
				{ company: "Optilab Solution", turnover: "€690,000/an", website: null, name: null },
				{ company: "Basicra Prod", turnover: "€1,200,000/an", website: "https://basicra.ro", name: null },
				{ company: "Euro Narcis", turnover: "€12,400,000/an", website: "https://www.euronarcis.ro", name: null },
				{ company: "EMT Solutions", turnover: "€270,000/an", website: "https://www.facebook.com/profile.php?id=100066835885188", name: null },
				{ company: "Central Moreni", turnover: "€117,000,000/an", website: null, name: null },
				{ company: "A D Profial Company", turnover: "€2,800,000/an", website: "https://profial.ro", name: null },
				{ company: "Barnett Mccall Recruitment", turnover: "€16,500,000/an", website: "https://www.gigroupholding.com", name: null },
				{ company: "Kardynal", turnover: "€970,000/an", website: "https://www.kindustrial.ro", name: null },
				{ company: "Ad Infinitum", turnover: "€230,000/an", website: "https://www.facebook.com/AdInfinitumRO/", name: null },
				{ company: "Bigmat Trade", turnover: "€1,300,000/an", website: null, name: null },
				{ company: "Proclinic", turnover: "€2,800,000/an", website: "https://pro-clinic.ro", name: null },
				{ company: "Cromalight", turnover: "€1,900,000/an", website: "https://www.top-led.ro", name: null },
				{ company: "Armcomp", turnover: "€4,300,000/an", website: "https://armcomp.ro", name: null },
				{ company: "Tehnic Media", turnover: "€400,000/an", website: "https://tehnicmedia.ro", name: null },
				{ company: "Oepia", turnover: "€8,700,000/an", website: "https://www.oepia.ro", name: null },
				{ company: "Consal Security", turnover: "€840,000/an", website: "http://www.consalsecurity.ro", name: null },
				{ company: "Oxygazon", turnover: "€1,250,000/an", website: "https://oxygazon.ro/", name: null },
				{ company: "Biedar Plast", turnover: "€1,020,000/an", website: null, name: null },
				{ company: "Top Market Comimpex", turnover: "€23,500,000/an", website: "https://topmarket.ro", name: null },
				{ company: "Rusartis", turnover: "€263,000/an", website: null, name: null },
				{ company: "Ager", turnover: "€10,200,000/an", website: "https://www.unikat-group.com", name: null },
				{ company: "Charisma", turnover: "€1,400,000/an", website: "https://www.charismatrade.ro", name: null },
				{ company: "Boldify", turnover: "€150,000/an", website: "https://www.facebook.com/boldify.studio/", name: null },
				{ company: "Ebac Tehnic", turnover: "€750,000/an", website: "https://ebactehnic.ro", name: null },
				{ company: "Traditii din natura", turnover: "€860,000/an", website: "https://razvanidicel.ro", name: null },
				{ company: "Vicmod", turnover: "€5,000,000/an", website: "https://www.mizar.ro", name: null },
				{ company: "Fiveplus", turnover: "€270,000/an", website: "https://www.fiveplus.ro", name: null },
				{ company: "Tecnostar Consulting", turnover: "€2,100,000/an", website: null, name: null },
				{ company: "Darom", turnover: "€1,400,000/an", website: "https://darom.ro", name: null },
				{ company: "Dinamica", turnover: "€20,900,000/an", website: "http://dinamicagrup.ro", name: null },
				{ company: "Simedo", turnover: "€5,800,000/an", website: null, name: null },
				{ company: "Emiral Media", turnover: "€300,000/an", website: "https://ancabandac.ro", name: null },
				{ company: "Nord Vest Camioane", turnover: "€9,800,000/an", website: "https://www.nordvestcamioane.eu/ro", name: null },
				{ company: "Future Line", turnover: "€470,000/an", website: "https://www.fli.ro", name: null },
				{ company: "Oprean", turnover: "€15,300,000/an", website: "https://oprean.ro", name: null },
				{ company: "Plasson", turnover: "€3,700,000/an", website: "https://plasson.ro", name: null },
				{ company: "MTC Cars Cluj", turnover: "€8,300,000/an", website: "https://www.webcar.eu/eu-en", name: null },
				{ company: "Apetit Catering", turnover: "€960,000/an", website: "http://apetit-catering.ro", name: null },
				{ company: "Salonpro", turnover: "€2,100,000/an", website: "https://www.salonpro.ro", name: null },
				{ company: "Astrid", turnover: "€1,200,000/an", website: null, name: null },
				{ company: "Graficard", turnover: "€190,000/an", website: "http://www.graficard.ro", name: null },
				{ company: "Belher", turnover: "€770,000/an", website: "https://www.belher.ro", name: null },
				{ company: "Interbabis", turnover: "€4,400,000/an", website: "https://www.interbabis.ro", name: null },
				{ company: "Steinweg", turnover: "€4,200,000/an", website: "https://www.steinweg.com", name: null },
				{ company: "Bibi Market", turnover: "€1,900,000/an", website: null, name: null },
				{ company: "Nomad Container", turnover: "€1,100,000/an", website: "https://nomadcontainer.com", name: null },
				{ company: "Abbate", turnover: "€3,400,000/an", website: "https://abbate.ro", name: null },
				{ company: "Angi San", turnover: "€1,600,000/an", website: "https://angisan.ro", name: null },
				{ company: "Medical Express", turnover: "€2,900,000/an", website: "https://www.medicalexpress.ro", name: null },
				{ company: "Mon Sport Shop", turnover: "€1,500,000/an", website: null, name: null },
				{ company: "CGR Business Project", turnover: "€2,800,000/an", website: "https://www.igiena-curatenie.ro", name: null },
				{ company: "Design Ideal", turnover: "€2,500,000/an", website: "http://www.amarket.ro", name: null },
				{ company: "Electric Valcor", turnover: "€4,600,000/an", website: "https://www.electricvalcor.ro", name: null },
				{ company: "Bia Si Com", turnover: "€4,100,000/an", website: "https://www.biasicom.ro", name: null },
				{ company: "Tech-con Group", turnover: "€7,400,000/an", website: "https://tech-congroup.com", name: null },
				{ company: "Firma de IT", turnover: "€185,000/an", website: "https://firmade.it", name: null },
				{ company: "Fabrica de Plase", turnover: "€620,000/an", website: "https://fabricadeplasesport.ro", name: null },
				{ company: "Total Survey", turnover: "€400,000/an", website: "https://www.totalsurvey.eu", name: null },
				{ company: "Auto Albina", turnover: "€1,000,000/an", website: "https://autoalbina.ro", name: null },
				{ company: "Acaju", turnover: "€6,700,000/an", website: "https://acaju.ro", name: null },
				{ company: "Mida Soft", turnover: "€20,000,000/an", website: "https://www.midasoft.ro", name: null },
				{ company: "Life is Hard", turnover: "€5,000,000/an", website: "https://www.lifeishard.ro", name: null },
			],
		};
	},
	props: {
		data: Object,
	},
	components: {
		IconClose,
	},
	async mounted() {
		setTimeout(() => {
			$(".opacity-page").addClass("show");
		}, 0);
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
	},
};
</script>
