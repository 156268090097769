<template>
	<div class="sidebar-tbf">
		<div class="logo-tbfdigital" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="59" :width="48" v-on:animCreated="handleAnimationLogo"/>
		</div>
		<div class="main-menu" v-if="$auth.ready() && $auth.check()">

			<div class="item-menu">
				<router-link :to="{name: 'homepage' }">
					<div class="border-left-active"></div>
					<icon-home class="icon-item"/>
				</router-link>
			</div>	
			<div class="item-menu">
				<router-link :to="{name: 'dashboard'}">
					<div class="border-left-active"></div>
					<icon-dashboard class="icon-item"/>
				</router-link>
			</div>
		</div>
		<div class="logout-sidebar">
			<template v-if="$auth.impersonating()">
				<div class="user-impersonate">
					Impersonate: {{ $auth.user().first_name }} {{ $auth.user().last_name }}
				</div>
				<div class="logout-action" @click="unimpersonate" >
					<icon-logout />
				</div>
			</template>
			<div class="logout-action" @click="$auth.logout()" v-else><icon-logout /></div>
		</div>
	</div>
</template>

<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconDashboard from '../Icons/Dashboard'
	import IconHome from '../Icons/Home'
	import IconLogout from '../Icons/Logout'

	export default {
		data() {
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true
			};
		},
		components: {
			Lottie,
			IconDashboard,
			IconHome,
			IconLogout
		},
		methods: {
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			},
			unimpersonate(){
				this.$auth.unimpersonate({
					makeRequest: true,
					redirect: { name: "admin-all-users" },
				});
			}
		}
	};
</script>