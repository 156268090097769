<template>
    <div class="modal-tbf modal-demo">
        <div class="container-modal opacity-page">
            <div class="header-modal">
                <div class="title">
                    Suntem aici sa te ajutăm!
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>
			<p>Completează acest formular și unul din consultanți TBF Digital te va contacta în maxim 48 de ore.</p>

            <div class="body-modal">
            	<div class="form-section">
            		
            		<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('demo_form.name') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
							<div class="icon-left"><icon-user /></div>
							<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('demo_form.name_placeholder')" class="input-text" v-model="name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('demo_form.email') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
							<div class="icon-left"><icon-email /></div>
							<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('demo_form.email_placeholder')" class="input-text" v-model="name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('demo_form.phone') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
							<div class="icon-left"><icon-phone /></div>
							<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('demo_form.phone_placeholder')" class="input-text" v-model="name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('demo_form.number_employees') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
							<div class="icon-left"><icon-users /></div>
							<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('demo_form.number_employees_placeholder')" class="input-text" v-model="name">
						</div>
					</div>
            	</div>
            	<div class="form-submit full-width">
            		<div class="space-button">
						<button class="btn-tbf grey center" @click="closeModal">
							<div class="loader"></div>
							<div class="text">{{ $t('general.cancel') }}</div>
						</button>
            		</div>
            		<div class="space-button">
						<button class="btn-tbf blue center">
							<div class="loader"></div>
							<div class="text">{{ $t('demo_form.send_request') }}</div>
						</button>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconClose from '../Icons/Close'
    import IconEmail from '../Icons/Email'
    import IconPhone from '../Icons/Phone'
    import IconUser from '../Icons/User'
    import IconUsers from '../Icons/Users'
	import { required } from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
            	name: ''
            };
        },
        props: {
            data: Object
        },
        components: {
            IconClose,
            IconEmail,
            IconPhone,
            IconUser,
            IconUsers
        },
		validations: {
			name: {required}
        },
        async mounted() {
            setTimeout(() => {
                $('.opacity-page').addClass('show')
            }, 0)
        },
        methods: {
            closeModal(){
                this.$emit("close_modal");
            }
        }
    };
</script>