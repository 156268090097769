<template>
	<div class="content">
		<div class="clients">
			<div class="title">
				<h2>Ne folosesc sistemele</h2>
				<p>
					Companii românești din 23 de industrii cu cifră totală de aproape 2 miliarde de euro pe an (€1,764,000,000) folosesc sistemele TBF. Peste 50 dintre aceste companii sunt numărul 1
					în industria lor.
				</p>
			</div>

			<div class="cards">
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/daniel-craciun-lensa.png')"></div>
					<div class="company">Lensa</div>
					<div class="name">Daniel Craciun</div>
					<div class="trunover">€26,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/cosmin-raileanu-vindem-ieftin.png')"></div>
					<div class="company">Vindem Ieftin</div>
					<div class="name">Cosmin Răileanu</div>
					<div class="trunover">€15,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/octavian-blajan-euroteam.png')"></div>
					<div class="company">Euro Team GB</div>
					<div class="name">Octavian Blăjan</div>
					<div class="trunover">€17,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/adrian-dragomir-termene.png')"></div>
					<div class="company">Termene</div>
					<div class="name">Adrian Dragomir</div>
					<div class="trunover">€2,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/marian-spinu-deepserv.png')"></div>
					<div class="company">Deep Serv</div>
					<div class="name">Marian Spînu</div>
					<div class="trunover">€9,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/mihai-vinatoru-dwf.png')"></div>
					<div class="company">DWF</div>
					<div class="name">Mihai Vânătoru</div>
					<div class="trunover">€4,500,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/marian-alecsiu-f64.png')"></div>
					<div class="company">F64</div>
					<div class="name">Marian Alecsiu</div>
					<div class="trunover">€28,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/delia-dragomir-eucom.png')"></div>
					<div class="company">Eucom</div>
					<div class="name">Delia Dragomir</div>
					<div class="trunover">€2,500,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/bernat-nyulas-fomco.png')"></div>
					<div class="company">Fomco</div>
					<div class="name">Bernat Nyulas</div>
					<div class="trunover">€27,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/lidia-fati-dacris.png')"></div>
					<div class="company">Dacris</div>
					<div class="name">Lidia Fați</div>
					<div class="trunover">€28,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/ciprian-chelariu-izotec.png')"></div>
					<div class="company">Izotec</div>
					<div class="name">Ciprian Chelariu</div>
					<div class="trunover">€8,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/lucian-florea-fryday.png')"></div>
					<div class="company">Fryday</div>
					<div class="name">Lucian Florea</div>
					<div class="trunover">€4,100,000 / an</div>
				</div>

				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/andu-stancu-frontera.png')"></div>
					<div class="company">Frontera</div>
					<div class="name">Alexandru Stancu</div>
					<div class="trunover">€9,500,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/bogdan-ghinea-eden.png')"></div>
					<div class="company">Eden Boutique</div>
					<div class="name">Bogdan Ghinea</div>
					<div class="trunover">€3,600,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/catalin-priscornita-blitz.png')"></div>
					<div class="company">Blitz Imobiliare</div>
					<div class="name">Cătălin Priscorniță</div>
					<div class="trunover">€5,200,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/dan-jalba-alphaclinic.png')"></div>
					<div class="company">Alpha Clinic</div>
					<div class="name">Dan Jalbă</div>
					<div class="trunover">€2,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/adrian-bianu-rinapack.png')"></div>
					<div class="company">Rina Pack</div>
					<div class="name">Adrian Bianu</div>
					<div class="trunover">€5,000,000 / an</div>
				</div>
				<div class="card-item">
					<div class="avatar" style="background-image: url('/build/people/stefan-irimia-allaboutparenting.png')"></div>
					<div class="company">All About Parenting</div>
					<div class="name">Ștefan Irimia</div>
					<div class="trunover">€2,500,000 / an</div>
				</div>
			</div>

			<!-- div cu vezi mai multe -->
			<div @click="showModal('clients')" class="see-clients">
				Vezi 300+ companii
			</div>
		</div>
		<div class="questions">
			<div class="questions-container">
				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_1") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_1") }}
						</div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_2") }}
						</div>
						<div class="answer" v-html="$t('footer.answer_2')"></div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_3") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_3") }}
						</div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_4") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_4") }}
						</div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_5") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_5") }}
						</div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_6") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_6") }}
						</div>
					</div>
				</div>

				<div class="question-card">
					<div class="icon" v-if="$resize && $mq.above(501)"><icon-question /></div>
					<div class="question-info">
						<div class="question">
							<span class="icon-inline" v-if="$resize && $mq.below(500)"><icon-question /></span>
							{{ $t("footer.question_7") }}
						</div>
						<div class="answer">
							{{ $t("footer.answer_7") }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconQuote from "../Icons/Quote";
import IconQuestion from "../Icons/Question";

export default {
	data() {
		return {};
	},
	components: {
		IconQuote,
		IconQuestion,
	},
	mounted() {},
	methods: {
		showModal(type, data = false) {
			this.$root.$emit("open_modal", type, data);
		},
	},
};
</script>
