<template>
	<div class="modal-tbf modal-discount-token">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">Adauga un token de reducere</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="container-content-modal">
					<div class="form-section">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Nume*</label>
								<div v-if="$v.name.$error" class="error-msg">{{ $t("validator.required") }}</div>
							</div>
							<div class="input-box" v-bind:class="{ has_error: $v.name.$error }">
								<div class="icon-left"><icon-discount /></div>
								<div class="icon-right" v-if="name" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
								<input type="text" placeholder="Numele token-ului de reducere" class="input-text" v-model="name" />
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">TBF tag*</label>
								<div v-if="$v.tbf_tag.$error" class="error-msg">{{ $t("validator.required") }}</div>
							</div>
							<div class="input-box" v-bind:class="{ has_error: $v.tbf_tag.$error }">
								<div class="icon-left"><icon-tbf /></div>
								<div class="icon-right" v-if="tbf_tag" @click.stop="tbf_tag = ''"><icon-close class="icon-clear" /></div>
								<input type="text" placeholder="TBF tag" class="input-text" v-model="tbf_tag" />
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Token*</label>
								<div v-if="$v.discount_token.$error || error_token" class="error-msg">{{ error_token ? error_token[0] : $t("validator.required") }}</div>
							</div>
							<div class="input-with-btn">
								<div class="input-box" v-bind:class="{ has_error: $v.discount_token.$error }">
									<div class="icon-left"><icon-password /></div>
									<div class="icon-right" v-if="discount_token" @click.stop="discount_token = ''"><icon-close class="icon-clear" /></div>
									<input type="text" placeholder="Token-ul de reducere" class="input-text" v-model="discount_token" />
								</div>
								<button class="btn-tbf white-blue center generate-token" @click="generateToken">
									<div class="text">Genereaza token</div>
								</button>
							</div>
						</div>

						<div class="input-group w-50">
							<div class="label-header">
								<label class="label">Status</label>
							</div>
							<div class="input-select-status">
								<div class="status-item active-btn" :class="{ active: is_active == 1 }" @click="is_active = 1">Activ</div>
								<div class="status-item inactive-btn" :class="{ active: is_active == 0 }" @click="is_active = 0">Inactiv</div>
							</div>
						</div>

						<div class="input-group w-50">
							<div class="label-header">
								<label class="label">Free (link folosit pentru acces gratuit)</label>
							</div>
							<div class="input-select-status">
								<div class="status-item active-btn" :class="{ active: is_free == 1 }" @click="is_free = 1">Da</div>
								<div class="status-item inactive-btn" :class="{ active: is_free == 0 }" @click="is_free = 0">Nu</div>
							</div>
						</div>
					</div>

					<div class="form-submit">
						<button class="btn-tbf grey center" @click="closeModal">
							<div class="loader"></div>
							<div class="text">{{ $t("general.cancel") }}</div>
						</button>
						<button id="buttonSave" class="btn-tbf blue center" @click="saveForm">
							<div class="loader"></div>
							<div class="text">{{ data.type == "create" ? $t("general.add") : $t("general.update") }}</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.modal-discount-token {
	.input-with-btn {
		display: flex;
		.input-box {
			flex: 1;
		}
	}

	.input-select-status {
		display: flex;
		.status-item {
			width: 100%;
			height: 40px;
			max-width: 150px;
			margin-right: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-radius: 9px;
			border: 1px solid #dcdee1;
			cursor: pointer;
			color: #181b30;
			font-size: 12px;
			font-weight: 500;
			&:hover {
				border: solid 1px #b0b1b3;
				background-color: #eaecee;
				&.active-btn {
					&.active {
						border: 1px solid #03ae58;
						background-color: rgba(11, 203, 106, 0.297449);
						color: $primary;
					}
				}
				&.inactive-btn {
					&.active {
						border: 1px solid #eb1b68;
						background-color: rgba(235, 27, 104, 0.2);
						color: $primary;
					}
				}
			}
			&.active-btn {
				&.active {
					border: 1px solid #03ae58;
					background-color: rgba(11, 203, 106, 0.297449);
					color: $primary;
				}
			}
			&.inactive-btn {
				&.active {
					border: 1px solid #eb1b68;
					background-color: rgba(235, 27, 104, 0.2);
					color: $primary;
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.generate-token {
		margin-left: 15px;
	}
	.form-submit {
		.btn-tbf {
			width: 50%;
		}
	}
}
</style>

<script type="text/javascript">
import IconClose from "../../Icons/Close";
import IconDiscount from "../../Icons/Discount";
import IconPassword from "../../Icons/Password";
import IconTbf from "../../Icons/Tbf";
import { required } from "vuelidate/lib/validators";

export default {
	data() {
		return {
			name: "",
			discount_token: "",
			tbf_tag: "",
			is_active: 1,
			is_free: 1,
			loaded: false,
			error_token: "",
		};
	},
	props: {
		data: Object,
	},
	components: {
		IconClose,
		IconDiscount,
		IconPassword,
		IconTbf,
	},
	validations: {
		name: { required },
		discount_token: { required },
		is_active: { required },
		is_free: { required },
		tbf_tag: { required },
	},
	async mounted() {
		if (this.data.type == "create") {
			this.loaded = true;
			setTimeout(() => {
				$(".opacity-page").addClass("show");
			}, 0);
		} else {
			this.name = this.data.item.name;
			this.discount_token = this.data.item.discount_token;
			this.is_active = this.data.item.is_active;
			this.is_free = this.data.item.is_free;
			this.tbf_tag = this.data.item.tbf_tag;

			setTimeout(() => {
				this.loaded = true;
				setTimeout(() => {
					$(".opacity-page").addClass("show");
				}, 0);
			}, 0);
		}
	},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
		saveForm() {
			var buttonName = `buttonSave`;
			var btnSubmit = document.getElementById(buttonName);
			var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`);
			var btnSubmitText = document.querySelector(`#${buttonName} .text`);

			btnSubmit.disabled = true;
			btnSubmit.classList.add("loading");
			btnSubmitLoader.classList.add("onProgress");
			btnSubmitText.innerHTML = this.$t("btn-submit.loading");

			this.error_token = "";
			this.$v.$touch();

			if (!this.$v.$invalid) {
				let formData = {
					name: this.name,
					discount_token: this.discount_token,
					is_active: this.is_active,
					is_free: this.is_free,
					tbf_tag: this.tbf_tag,
				};

				if (this.data.type == "create") {
					this.store(formData, btnSubmit, btnSubmitText, btnSubmitLoader);
				} else {
					this.update(formData, btnSubmit, btnSubmitText, btnSubmitLoader);
				}
			} else {
				btnSubmitLoader.classList.add("finish");
				setTimeout(() => {
					btnSubmitText.innerHTML = this.$t("btn-submit.error");
					btnSubmit.classList.add("error");
					btnSubmitLoader.classList.remove("onProgress", "finish");
					btnSubmit.classList.remove("loading");
					setTimeout(() => {
						btnSubmit.classList.remove("error");
						btnSubmitText.innerHTML = this.data.type == "create" ? this.$t("general.add") : this.$t("general.update");
						btnSubmit.disabled = false;
					}, 1000);
				}, 300);
			}
		},
		store(formData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			axios
				.post("/discount-links/store", formData)
				.then(() => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$root.$emit("refreshDiscountTokensIndex");
							this.closeModal();
						}, 500);
					}, 300);
				})
				.catch((error) => {
					if (error.response.data && error.response.data.errors && error.response.data.errors.discount_token) {
						this.error_token = error.response.data.errors.discount_token;
					}

					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.data.type == "create" ? this.$t("general.add") : this.$t("general.update");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				});
		},
		update(formData, btnSubmit, btnSubmitText, btnSubmitLoader) {
			axios
				.patch(`/discount-links/${this.data.item.id}`, formData)
				.then(() => {
					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.success");
						btnSubmit.classList.add("completed");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							this.$root.$emit("refreshDiscountTokensIndex");
							this.closeModal();
						}, 500);
					}, 300);
				})
				.catch((error) => {
					if (error.response.data && error.response.data.errors && error.response.data.errors.discount_token) {
						this.error_token = error.response.data.errors.discount_token;
					}

					btnSubmitLoader.classList.add("finish");
					setTimeout(() => {
						btnSubmitText.innerHTML = this.$t("btn-submit.error");
						btnSubmit.classList.add("error");
						btnSubmitLoader.classList.remove("onProgress", "finish");
						btnSubmit.classList.remove("loading");
						setTimeout(() => {
							btnSubmit.classList.remove("error");
							btnSubmitText.innerHTML = this.data.type == "create" ? this.$t("general.add") : this.$t("general.update");
							btnSubmit.disabled = false;
							if (error.response.status == 500) {
								this.error_message = this.$t("error.500");
							}
						}, 1000);
					}, 300);
				});
		},
		generateToken() {
			this.discount_token =
				Math.random()
					.toString(20)
					.substr(2) +
				Math.random()
					.toString(20)
					.substr(2);
		},
	},
};
</script>
