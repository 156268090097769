<template>
    <div class="modal-tbf modal-user-rights">
        <div class="container-modal opacity-page">
            <div class="header-modal">
                <div class="title">
                    {{ data.name }}
                    {{ loaded }}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="body-modal">
            	<div class="form-section">
            		<div class="info-rights" v-if="loaded === 3">
						<div class="soft">
                            <div class="soft-title">
                                {{ $t('admin_users.organigram')}}
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.general_right')}}
                                </div>
                                <div class="type">
                                    {{ $t(`profile.rights_name.${rightsHr.right}`) }}
                                </div>
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.project_manager_right')}}
                                </div>
                                <div class="type">
                                    {{ rightsHr.project_manager === 1 ? $t('general.yes') : $t('general.no')}}
                                </div>
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.evaluate_past_responsibility_right')}}
                                </div>
                                <div class="type">
                                    {{ rightsHr.evaluate_past_responsibility === 1 ? $t('general.yes') : $t('general.no')}}
                                </div>
                            </div>
                        </div>
						<div class="soft">
                            <div class="soft-title">
                                {{ $t('admin_users.procedures')}}
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.general_right')}}
                                </div>
                                <div class="type">
                                    {{ $t(`profile.rights_name.${rightsProcedures.general}`) }}
                                </div>
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.special_rights_procedures')}}
                                </div>
                                <div class="type">
                                    {{ rightsProcedures.procedure_role_admin ? rightsProcedures.procedure_role_admin.join(', ') : $t('general.no') }}
                                </div>
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.special_rights_processes')}}
                                </div>
                                <div class="type">
                                    {{ rightsProcedures.process_admin ? rightsProcedures.process_admin.join(', ') : $t('general.no') }}
                                </div>
                            </div>
                        </div>
						<div class="soft">
                            <div class="soft-title">
                                {{ $t('admin_users.objectives')}}
                            </div>
                            <div class="line">
                                <div class="title">
                                    {{ $t('profile.general_right')}}
                                </div>
                                <div class="type">
                                    {{ $t(`profile.rights_name.${rightsObjectives}`) }}
                                </div>
                            </div>
                        </div>
					</div>
                    <div class="loader" v-else></div>
            	</div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconClose from '../Icons/Close'

    export default {
        data() {
            return {
                rightsHr: {},
                rightsObjectives: {},
                rightsProcedures: {},
                loaded: 0
            };
        },
        props: {
            data: Object
        },
        components: {
            IconClose,
        },
        async mounted() {
            setTimeout(() => {
                $('.opacity-page').addClass('show')
            }, 0)

            this.getRightsFromHR()
            this.getRightsFromProcedures()
            this.getRightsFromObjectives()
        },
        methods: {
            closeModal(){
                this.$emit("close_modal");
            },
            async getRightsFromHR(){
                await axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/users/${this.data.slug}/rights`)
                .then(({data}) => {
                    this.rightsHr = data.data
                })
                .finally( ()=> {
                    this.loaded++
                })
            },
            async getRightsFromProcedures(){
                await axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.data.slug}/rights`)
                .then(({data}) => {
					this.rightsProcedures = {
						general: data.data.right
					};

                    if( data.data.procedure_role_admin ) {
						this.rights.procedures.procedure_role_admin = data.data.procedure_role_admin;
					}

					if( data.data.process_admin ) {
						this.rights.procedures.process_admin = data.data.process_admin;
					}
                })
                .finally( ()=> {
                    this.loaded++
                })
            },
            async getRightsFromObjectives(){
                await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/users/${this.data.slug}/rights`)
                .then(({data}) => {
                    this.rightsObjectives = data.data
                })
                .finally( ()=> {
                    this.loaded++
                })

            }
        }
    };
</script>