<template>
	<div class="main-template">
		<sidebar v-if="$resize && $mq.above(781)" />
		<div class="container-tbf">
			<navbar />
			<div class="container-content">
				<slot></slot>
			</div>
            <sidebar-mobile v-if="$resize && $mq.below(780)"/>
		</div>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModal()"></div>
            <modal-show-instance id="modal_show_instance" v-if="show_modal.show_instance" :data="data.show_instance" @close_modal="closeModal('show_instance')"/>
            <modal-report-vouchers id="modal_report_vouchers" v-if="show_modal.report_vouchers" :data="data.report_vouchers" @close_modal="closeModal('report_vouchers')"/>
            <modal-create-discount-token id="modal_create_discount_token" v-if="show_modal.create_discount_token" :data="data.create_discount_token" @close_modal="closeModal('create_discount_token')"/>
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <modal-user-rights id="modal_user_rights" v-if="show_modal.user_rights" :data="data.user_rights" @close_modal="closeModal('user_rights')"/>
        </div>
	</div>
</template>

<script>
    import Sidebar from '../General/SidebarMenu'
    import SidebarMobile from "../General/SidebarMobile"
    import Navbar from '../General/NavbarDashboard'
    import ModalShowInstance from '../Admin/ShowInstance'
    import ModalReportVouchers from '../Modals/ReportVouchers'
    import ModalCreateDiscountToken from '../Admin/DiscountTokens/ModalCreate'
    import ModalDelete from '../Modals/Delete'
    import ModalUserRights from '../Modals/UserRights'

    export default {
        data() {
            return {
                show_overflow: false,
                show_modal: {
                    show_instance: false,
                    report_vouchers: false,
                    delete: false,
                    create_discount_token: false,
                    user_rights: false
                },
                data: {
                    show_instance: {},
                    report_vouchers: {},
                    delete: {},
                    create_discount_token: {},
                    user_rights: {}
                }
            }
        },
        components: {
            Sidebar,
            Navbar,
            SidebarMobile,
            ModalShowInstance,
            ModalReportVouchers,
            ModalCreateDiscountToken,
            ModalDelete,
            ModalUserRights
        },
        mounted(){
            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.$store.dispatch('packages/getPackages');


            if(this.$auth.user().rights.mini_admin == 'true'){
                this.$store.dispatch('applications/getApplications');
            }
        },
        methods:{
            closeModal(type = false, closeOverflow = true){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false

                    if(closeOverflow){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            }
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/main-template.scss";
    @import "../../styles/pages/admin_list.scss";
    @import "../../styles/admin/form_admin.scss";
</style>