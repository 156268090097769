<template>
	<div class="full-template">
		<slot></slot>

		<div class="overflow-modal overflow-full-template" v-if="show_overflow">
			<div class="bg-modals" @click="closeModal()"></div>
			<modal-demo id="modal_demo" v-if="show_modal.demo" :data="data.demo" @close_modal="closeModal('demo')" />
			<modal-clients id="modal_clients" v-if="show_modal.clients" :data="data.clients" @close_modal="closeModal('clients')" />
		</div>
	</div>
</template>

<script>
import ModalDemo from "../Modals/Demo";
import ModalClients from "../Modals/Clients";

export default {
	data() {
		return {
			show_overflow: false,
			show_modal: {
				demo: false,
				clients: false,
			},
			data: {
				demo: {},
				clients: {},
			},
		};
	},
	components: {
		ModalDemo,
		ModalClients,
	},
	mounted() {
		this.$root.$on("open_modal", (type, data = false) => {
			this.active_modal = type;
			this.data[type] = data ? data : {};

			setTimeout(() => {
				this.show_overflow = true;
				this.show_modal[type] = true;
				setTimeout(() => {
					$(".overflow-modal").addClass("show");
					$("#modal_" + type).addClass("active");
				}, 0);
			}, 0);
		});
	},
	methods: {
		closeModal(type = false, closeOverflow = true) {
			var target_modal = type ? type : this.active_modal;
			$("#modal_" + target_modal).removeClass("active");

			setTimeout(() => {
				this.show_modal[target_modal] = false;

				if (closeOverflow) {
					$(".overflow-modal").removeClass("show");
					setTimeout(() => {
						this.show_overflow = false;
					}, 200);
				}
			}, 200);
		},
	},
};
</script>
