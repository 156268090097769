<template>
	<div class="template-public">
		<div class="main-page-register">
			<router-view/>
		</div>

		<div class="footer-register">
			<Footer />
		</div>

  		<div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModal()"></div>
            <modal-small-login id="modal_small_login" v-if="show_modal.small_login" :data="data.small_login" @close_modal="closeModal('small_login')"/>
            <modal-has-all-applications id="modal_has_all_applications" v-if="show_modal.has_all_applications" @close_modal="closeModal('has_all_applications')"/>
            <modal-error-payment id="modal_error_payment" v-if="show_modal.error_payment" @close_modal="closeModal('error_payment')"/>
            <modal-cant-create-account id="modal_cant_create_account" v-if="show_modal.cant_create_account" :data="data.cant_create_account" @close_modal="closeModal('cant_create_account')"/>
        </div>
    </div>
</template>

<script type="text/javascript">

	import ModalSmallLogin from '../Auth/SmallLoginModal'
	import ModalHasAllApplications from '../Auth/ModalHasAllApplications'
	import ModalErrorPayment from '../Auth/ModalErrorPayment'
	import ModalCantCreateAccount from '../Auth/ModalCantCreateAccount'
	import Footer from "../Auth/Footer.vue"

	export default {
		data() {
			return {
				show_modal: {
					small_login: false,
					has_all_applications: false,
					error_payment: false,
					cant_create_account: false
				},
				show_overflow: false,
				active_modal: '',
				data: {
					small_login: {},
					has_all_applications: {},
					error_payment: {},
					cant_create_account: {}
				}
			};
		},
		components: {
			ModalSmallLogin,
			ModalHasAllApplications,
			ModalErrorPayment,
			ModalCantCreateAccount,
			Footer
		},
		mounted() {
			this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : {}

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        document.querySelector('.overflow-modal').classList.add('show')
                        document.getElementById("modal_" + type).classList.add("active")
                    }, 0);
                }, 0);
            })
		},
		methods: {
			closeModal(type = false){
				if(!['has_all_applications', 'error_payment'].includes(this.active_modal)){
					var target_modal = type ? type : this.active_modal
					document.getElementById("modal_" + target_modal).classList.remove("active")

	                setTimeout(() => {
	                    this.show_modal[target_modal] = false
	                    document.querySelector('.overflow-modal').classList.remove("show")
	                    setTimeout(() => {
	                    	this.show_overflow = false;
	                    }, 200)
	                }, 200);
				}
			},
			checkLanguage(){
				if(this.$auth.check()){
					this.$i18n.locale = this.$auth.user().language
					this.$cookies.set('language', this.$auth.user().language, '1m')
				}else if(this.$cookies.get('language')){
					this.$i18n.locale = this.$cookies.get('language')
				}
			}
		}
	};
</script>

<style lang="scss">
    @import "../../styles/templates/login-template.scss";
    @import "../../styles/templates/new-register.scss";
	@import "../../styles/templates/footer.scss";
</style>