import Vue       from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

function loadView(view) {
    return () => import(`../components/${view}.vue`);
}

const routes = [
	{ path: '/login', name: 'login', component: loadView('Auth/Login'), meta: { auth: false, layout: 'login-template'}},
	{ path: '/register', name: 'register', component: loadView('Auth/Register'), meta: { auth: false, layout: 'login-template'}},
	{ path: '/register-packages', name: 'register-packages', component: loadView('Auth/Register'), meta: { auth: undefined, layout: 'login-template'}},

	{ path: '/register-success', name: 'register-success', component: loadView('Auth/RegisterSuccess'), meta: { auth: true, layout: 'login-template'}},
	{ path: '/register-success-op', name: 'register-success-op', component: loadView('Auth/RegisterSuccessOp'), meta: { auth: true, layout: 'login-template' }},
	
	{ path: '/password/forgot', name: 'password-forgot', component: loadView('Auth/ForgotPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/password/forgot-success', name: 'password-forgot-success', component: loadView('Auth/ForgotPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password/:token', name: 'password-reset', component: loadView('Auth/ResetPassword'), meta: { auth: false, layout: 'login-template' }},
	{ path: '/reset-password-success', name: 'password-reset-success', component: loadView('Auth/ResetPasswordSuccess'), meta: { auth: false, layout: 'login-template' }},

	{ path: '/admin/users', name: 'admin-users', component: loadView('Admin/Users'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/all-users', name: 'admin-all-users', component: loadView('Admin/AllUsers'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/report', name: 'admin-report', component: loadView('Admin/Report'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/report-daily', name: 'admin-report-daily', component: loadView('Admin/ReportDaily'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/discount-tokens', name: 'admin-discount-tokens', component: loadView('Admin/DiscountTokens/Index'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/updates-notifications', name: 'admin-updates', component: loadView('Admin/UpdatesNotifications/Index'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/updates-notifications/create', name: 'admin-updates-create', component: loadView('Admin/UpdatesNotifications/Create'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/updates-notifications/edit/:id', name: 'admin-updates-edit', component: loadView('Admin/UpdatesNotifications/Create'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/future-updates', name: 'admin-future-updates', component: loadView('Admin/FutureUpdates/Index'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/future-updates/create', name: 'admin-future-updates-create', component: loadView('Admin/FutureUpdates/Create'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},
	{ path: '/admin/future-updates/edit/:id', name: 'admin-future-updates-edit', component: loadView('Admin/FutureUpdates/Create'), meta: { auth: {mini_admin: 'true'}, layout: 'main-template' }},

	// { path: '/', name: 'homepage', component: loadView('Pages/Home'), meta: { auth: undefined, layout: 'full-template' }},
	{ path: '/', name: 'homepage', component: loadView('Pages/Price'), meta: { auth: true, layout: 'full-template' }},
	{ path: '/price', name: 'pricing', component: loadView('Pages/Price'), meta: { auth: undefined, layout: 'full-template' }},

	{ path: '/dashboard', name: 'dashboard', component: loadView('Pages/Dashboard'), meta: { auth: true, layout: 'main-template' }},

	{ path: '/404', name: 'not-found', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'full-template' } },
	{ path: '*', name: 'not-exist', component: loadView('Pages/NotFound'), meta: { auth: undefined, layout: 'full-template' } }
]

Vue.router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
	linkActiveClass: 'active',
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
})

export default Vue.router;